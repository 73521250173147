export default {
  address: "新北市八里區中山路一段306巷基地內",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d536.823512047139!2d121.41318982621395!3d25.1592200336074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a53fe99ba411%3A0xf91d72552183104a!2z5bem5bK4546r55Gw!5e0!3m2!1szh-TW!2stw!4v1672389487646!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/QJehUJ9R5kwwiGm567",
  phone: "02-8630-5938",
  fbLink:
    "https://www.facebook.com/%E5%B7%A6%E5%B2%B8%E7%8E%AB%E7%91%B0-103086948079450",
  fbMessage: "https://m.me/103086948079450/",
  caseName: "左岸玫瑰",
  indigatorLength: 10,

  houseInfos: [
    ["基地位置", "新北市八里區中山路一段308號"],
    ["建蔽率", "30.92%"],
    ["投資建設", "佳瑞建設股份有限公司"],
    ["樓層規劃", "地上14層，地下3層"],
    ["建築設計", "閤康聯合建築師事務所"],
    ["基地面積", "1182.69坪"],
    ["公設比", "32.92%"],
    ["棟戶規劃", "1幢，2棟，157戶住家，4戶店面"],
    ["營造公司", "萬代福營造有限公司"],
    ["建造執照", "108八建字第00596號等1個"],
    ["企劃銷售", "尚邑廣告"]
  ],

  gtmCode: [""], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
    title: "預約賞屋",
    subTitle: ""
  }
};
