export default [
  {
    name: "大林口俯瞰圖",
    //imgSrc: require("../assets/img/nav/view.png"),
    subTitle: "",
    section: "section2",
    svgSrc: "",
    offset: 0,
    mobileOffset: -60
  },
  {
    name: "全時交通",
    imgSrc: "",
    subTitle: "",
    section: "section3",
    svgSrc: "",
    offset: 0,
    mobileOffset: -50
  },
  {
    name: "願景未來",
    imgSrc: "",
    subTitle: "",
    section: "section4",
    svgSrc: "",
    offset: 100,
    mobileOffset: -50
  },
  {
    name: "機能學區",
    imgSrc: "",
    subTitle: "",
    section: "section5",
    svgSrc: "",
    offset: -150,
    mobileOffset: -50
  },
  {
    name: "公園綠地",
    imgSrc: "",
    subTitle: "",
    section: "section6",
    svgSrc: "",
    offset: 320,
    mobileOffset: -50
  },
  {
    name: "極上公設",
    imgSrc: "",
    subTitle: "",
    section: "section6",
    svgSrc: "",
    offset: 1250,
    mobileOffset: 700
  },
  {
    name: "閱讀人文",
    imgSrc: "",
    subTitle: "",
    section: "section7",
    svgSrc: "",
    offset: 100,
    mobileOffset: 0
  },
  {
    name: "預約賞屋",
    imgSrc: "",
    subTitle: "",
    section: "contact",
    svgSrc: "",
    offset: -200,
    mobileOffset: -50
  }
];
