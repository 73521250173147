<template>
  <div class="section1">
    <div class="sub-section topbg">
      <div class="main-title" data-aos="fade-right" data-aos-duration="1500">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 910.57 502.82">
           <g id="圖層_2" data-name="圖層 2">
        <g id="圖層_1-2" data-name="圖層 1">
            <path class="cls-1" d="M271.89,217.17c0,2.75,0,5,.14,6.21.09.82.26,1.45,1.12,1.57a11.59,11.59,0,0,0,1.29.11c.17,0,.22.09.22.17s-.14.23-.45.23c-1.58,0-3.38-.08-3.52-.08s-1.94.08-2.8.08c-.32,0-.46-.05-.46-.23s.06-.17.23-.17a4.22,4.22,0,0,0,.86-.11c.57-.12.71-.75.83-1.57.14-1.21.14-3.46.14-6.21V205.08l-4.23,0c-1.77,0-2.46.23-2.92.92a4.58,4.58,0,0,0-.48.89c-.09.22-.18.28-.29.28s-.14-.08-.14-.26.57-2.74.63-3,.17-.54.28-.54a5.63,5.63,0,0,0,1.26.34c.83.09,1.92.14,2.26.14h10.72a11.15,11.15,0,0,0,2-.14c.43-.06.69-.14.8-.14s.14.17.14.34c0,.86-.08,2.83-.08,3.15s-.09.37-.2.37-.2-.09-.23-.49l0-.31c-.09-.86-.77-1.52-3.52-1.58l-3.6,0Z" />
            <path class="cls-1" d="M302.83,217.17c0,2.75,0,5,.14,6.21.08.82.26,1.45,1.11,1.57a11.59,11.59,0,0,0,1.29.11c.17,0,.23.09.23.17s-.14.23-.46.23c-1.57,0-3.37-.08-3.51-.08s-1.95.08-2.81.08c-.31,0-.45-.05-.45-.23s.05-.17.22-.17a4.13,4.13,0,0,0,.86-.11c.57-.12.72-.75.83-1.57.14-1.21.14-3.46.14-6.21v-3a.16.16,0,0,0-.17-.17H288.13c-.09,0-.17,0-.17.17v3c0,2.75,0,5,.14,6.21.09.82.26,1.45,1.12,1.57a11.64,11.64,0,0,0,1.28.11c.18,0,.23.09.23.17s-.14.23-.46.23c-1.57,0-3.37-.08-3.51-.08s-1.95.08-2.8.08c-.32,0-.46-.05-.46-.23s.06-.17.23-.17a4.13,4.13,0,0,0,.85-.11c.58-.12.72-.75.83-1.57.15-1.21.15-3.46.15-6.21v-5c0-4.46,0-5.26-.06-6.18s-.29-1.43-1.23-1.63a6,6,0,0,0-1-.08c-.12,0-.23-.06-.23-.17s.14-.23.46-.23c1.28,0,3.08.08,3.23.08s1.94-.08,2.8-.08c.31,0,.46.05.46.23s-.12.17-.23.17a5.15,5.15,0,0,0-.72.08c-.77.15-1,.63-1.05,1.63s-.06,1.72-.06,6.18v.6c0,.14.08.17.17.17h12.12c.09,0,.17,0,.17-.17v-.6c0-4.46,0-5.26-.05-6.18s-.29-1.43-1.23-1.63a6.14,6.14,0,0,0-1-.08c-.12,0-.23-.06-.23-.17s.14-.23.45-.23c1.29,0,3.09.08,3.24.08s1.94-.08,2.8-.08c.31,0,.45.05.45.23s-.11.17-.22.17a5,5,0,0,0-.72.08c-.77.15-1,.63-1.06,1.63s-.05,1.72-.05,6.18Z" />
            <path class="cls-1" d="M313,212.14c0-4.46,0-5.26-.05-6.18s-.29-1.43-1.23-1.63a6.17,6.17,0,0,0-1-.08c-.11,0-.22-.06-.22-.17s.14-.23.45-.23c.66,0,1.43,0,2.06,0l1.17.05c.2,0,5.89,0,6.55,0a6.5,6.5,0,0,0,1.23-.14c.14,0,.26-.14.4-.14s.12.11.12.26-.15.54-.23,1.34c0,.29-.09,1.54-.15,1.89,0,.14-.08.31-.2.31s-.23-.14-.23-.37a2.89,2.89,0,0,0-.17-1c-.2-.46-.48-.8-2-1-.48-.06-3.6-.09-3.91-.09-.12,0-.17.09-.17.29v7.89c0,.2,0,.29.17.29.37,0,3.89,0,4.54-.06s1.09-.12,1.35-.4.31-.37.43-.37.14.05.14.23-.14.65-.23,1.6c-.06.57-.12,1.63-.12,1.83s0,.54-.19.54-.2-.12-.2-.26a2.78,2.78,0,0,0-.12-.94c-.11-.4-.37-.89-1.49-1-.77-.09-3.6-.15-4.08-.15-.15,0-.2.09-.2.21v2.51c0,1,0,4.29,0,4.89.08,2,.51,2.35,3.31,2.35a8.1,8.1,0,0,0,2.75-.32c.74-.31,1.08-.89,1.28-2.06.06-.31.12-.43.26-.43s.17.23.17.43-.23,2.17-.37,2.77-.43.75-1.54.75c-2.15,0-3.72-.06-4.84-.09s-1.8-.08-2.23-.08c-.05,0-.54,0-1.14,0s-1.23.05-1.66.05-.46-.05-.46-.23.06-.17.23-.17A4.22,4.22,0,0,0,312,225c.57-.12.72-.75.83-1.57.14-1.21.14-3.46.14-6.21Z" />
            <path class="cls-1" d="M357.46,221.52h.12c.77-2.63,3.4-10.93,4.4-14.58a6.56,6.56,0,0,0,.31-1.83c0-.52-.31-.86-1.63-.86a.23.23,0,0,1-.25-.2c0-.15.14-.2.48-.2,1.46,0,2.66.08,2.92.08s1.43-.08,2.31-.08c.26,0,.4.05.4.17s-.08.23-.25.23a3.84,3.84,0,0,0-1,.14c-.86.31-1.32,1.52-1.86,3.09-1.26,3.63-4.15,12.72-5.26,15.92-.75,2.12-.89,2.46-1.2,2.46s-.46-.37-.86-1.48l-5.61-15.93h-.05c-1,2.75-4.95,13.24-6,15.9-.46,1.2-.6,1.51-.94,1.51s-.43-.31-1-2.26l-5.09-16.95c-.46-1.52-.83-2-1.58-2.23a4.74,4.74,0,0,0-1.28-.17c-.14,0-.26,0-.26-.2s.17-.2.46-.2c1.49,0,3.06.08,3.37.08s1.52-.08,2.95-.08c.4,0,.51.08.51.2s-.14.2-.28.2a2.83,2.83,0,0,0-1.12.17.74.74,0,0,0-.37.69,23.55,23.55,0,0,0,.54,2.63c.43,1.83,3.4,12.32,3.83,13.83h.06l6.06-16.32c.43-1.17.54-1.32.72-1.32s.37.38.71,1.35Z" />
            <path class="cls-1" d="M388.45,217.17c0,2.75,0,5,.15,6.21.08.82.25,1.45,1.11,1.57a11.59,11.59,0,0,0,1.29.11c.17,0,.23.09.23.17s-.15.23-.46.23c-1.57,0-3.37-.08-3.52-.08s-1.94.08-2.8.08c-.31,0-.46-.05-.46-.23s.06-.17.23-.17a4.22,4.22,0,0,0,.86-.11c.57-.12.71-.75.83-1.57.14-1.21.14-3.46.14-6.21v-3a.16.16,0,0,0-.17-.17H373.76c-.09,0-.17,0-.17.17v3c0,2.75,0,5,.14,6.21.08.82.26,1.45,1.11,1.57a11.59,11.59,0,0,0,1.29.11c.17,0,.23.09.23.17s-.14.23-.46.23c-1.57,0-3.37-.08-3.52-.08s-1.94.08-2.8.08c-.31,0-.45-.05-.45-.23s.05-.17.22-.17a4.22,4.22,0,0,0,.86-.11c.57-.12.72-.75.83-1.57.14-1.21.14-3.46.14-6.21v-5c0-4.46,0-5.26-.05-6.18s-.29-1.43-1.23-1.63a6.14,6.14,0,0,0-1-.08c-.12,0-.23-.06-.23-.17s.14-.23.45-.23c1.29,0,3.09.08,3.23.08s1.95-.08,2.81-.08c.31,0,.45.05.45.23s-.11.17-.22.17a5,5,0,0,0-.72.08c-.77.15-1,.63-1.06,1.63s-.05,1.72-.05,6.18v.6c0,.14.08.17.17.17h12.12c.09,0,.17,0,.17-.17v-.6c0-4.46,0-5.26-.06-6.18s-.28-1.43-1.22-1.63a6.17,6.17,0,0,0-1-.08c-.11,0-.23-.06-.23-.17s.15-.23.46-.23c1.29,0,3.09.08,3.23.08s1.95-.08,2.8-.08c.32,0,.46.05.46.23s-.11.17-.23.17a4.89,4.89,0,0,0-.71.08c-.77.15-1,.63-1.06,1.63s-.06,1.72-.06,6.18Z" />
            <path class="cls-1" d="M405.92,203.45c6.43,0,11.24,4.06,11.24,10.72,0,6.4-4.52,11.69-11.41,11.69-7.83,0-11.09-6.09-11.09-11.23C394.66,210,398,203.45,405.92,203.45Zm.6,21.33c2.58,0,7.89-1.49,7.89-9.81,0-6.86-4.17-10.55-8.69-10.55-4.77,0-8.35,3.14-8.35,9.41C397.37,220.49,401.38,224.78,406.52,224.78Z" />
            <path class="cls-1" d="M425.62,217.23c0,4.2.11,6,.63,6.55s1.2.65,3.43.65c1.52,0,2.77,0,3.46-.85a4,4,0,0,0,.74-1.72c0-.23.09-.37.26-.37s.17.11.17.43a18.23,18.23,0,0,1-.43,2.86c-.2.65-.28.77-1.8.77-2.06,0-3.54-.06-4.74-.09s-2.09-.08-2.92-.08c-.11,0-.6,0-1.17,0s-1.2.05-1.63.05-.46-.05-.46-.23.06-.17.23-.17a4.22,4.22,0,0,0,.86-.11c.57-.12.71-.75.83-1.57.14-1.21.14-3.46.14-6.21v-5c0-4.46,0-5.26-.06-6.18s-.28-1.43-1.23-1.63a6,6,0,0,0-1-.08c-.11,0-.23-.06-.23-.17s.14-.23.46-.23c1.29,0,3.09.08,3.23.08s2.23-.08,3.09-.08c.31,0,.45.05.45.23s-.11.17-.22.17a8,8,0,0,0-.95.08c-.83.15-1.06.63-1.11,1.63s-.06,1.72-.06,6.18Z" />
            <path class="cls-1" d="M440.14,212.14c0-4.46,0-5.26-.05-6.18s-.29-1.43-1.23-1.63a6.14,6.14,0,0,0-1-.08c-.12,0-.23-.06-.23-.17s.14-.23.45-.23c.66,0,1.43,0,2.06,0l1.17.05c.21,0,5.89,0,6.55,0a6.5,6.5,0,0,0,1.23-.14c.14,0,.26-.14.4-.14s.12.11.12.26-.15.54-.23,1.34c0,.29-.09,1.54-.15,1.89,0,.14-.08.31-.2.31s-.22-.14-.22-.37a2.93,2.93,0,0,0-.18-1c-.2-.46-.48-.8-2-1-.48-.06-3.6-.09-3.91-.09-.12,0-.17.09-.17.29v7.89c0,.2,0,.29.17.29.37,0,3.89,0,4.54-.06s1.09-.12,1.35-.4.31-.37.43-.37.14.05.14.23-.14.65-.23,1.6c-.06.57-.11,1.63-.11,1.83s0,.54-.2.54-.2-.12-.2-.26a2.78,2.78,0,0,0-.12-.94c-.11-.4-.37-.89-1.49-1-.77-.09-3.6-.15-4.08-.15-.15,0-.2.09-.2.21v2.51c0,1,0,4.29,0,4.89.08,2,.51,2.35,3.31,2.35a8.1,8.1,0,0,0,2.75-.32c.74-.31,1.08-.89,1.28-2.06.06-.31.12-.43.26-.43s.17.23.17.43-.23,2.17-.37,2.77-.43.75-1.54.75c-2.15,0-3.72-.06-4.83-.09s-1.81-.08-2.24-.08c0,0-.54,0-1.14,0s-1.23.05-1.66.05-.45-.05-.45-.23.05-.17.22-.17a4.22,4.22,0,0,0,.86-.11c.57-.12.72-.75.83-1.57.14-1.21.14-3.46.14-6.21Z" />
            <path class="cls-1" d="M467.65,221.57c.05,2.35.34,3.09,1.11,3.32a6.45,6.45,0,0,0,1.43.17c.14,0,.23.06.23.17s-.17.23-.51.23c-1.63,0-2.78-.08-3-.08s-1.45.08-2.74.08c-.29,0-.46,0-.46-.23s.09-.17.23-.17a4.14,4.14,0,0,0,1.17-.17c.72-.2.86-1,.86-3.63l0-16.38c0-1.12.09-1.43.31-1.43s.72.63,1,.91c.42.49,4.68,5.09,9.09,9.81,2.83,3,5.94,6.52,6.86,7.46l-.31-14.52c0-1.86-.23-2.49-1.12-2.72a7.91,7.91,0,0,0-1.4-.14c-.2,0-.23-.09-.23-.2s.23-.2.57-.2c1.29,0,2.66.08,3,.08s1.25-.08,2.43-.08c.31,0,.51,0,.51.2s-.11.2-.31.2a2.68,2.68,0,0,0-.69.08c-.94.2-1.06.83-1.06,2.55l-.06,16.75c0,1.89-.05,2-.25,2s-.57-.31-2.09-1.83c-.31-.28-4.43-4.49-7.46-7.75-3.32-3.57-6.55-7.17-7.46-8.2Z" />
            <path class="cls-1" d="M494,212.14c0-4.46,0-5.26-.06-6.18s-.28-1.43-1.23-1.63a6,6,0,0,0-1-.08c-.11,0-.23-.06-.23-.17s.15-.23.46-.23c.66,0,1.43,0,2.06,0l1.17.05c.2,0,5.89,0,6.55,0a6.61,6.61,0,0,0,1.23-.14c.14,0,.25-.14.4-.14s.11.11.11.26-.14.54-.23,1.34c0,.29-.08,1.54-.14,1.89,0,.14-.09.31-.2.31s-.23-.14-.23-.37a3.13,3.13,0,0,0-.17-1c-.2-.46-.49-.8-2-1-.49-.06-3.6-.09-3.92-.09-.11,0-.17.09-.17.29v7.89c0,.2,0,.29.17.29.37,0,3.89,0,4.55-.06s1.09-.12,1.34-.4.32-.37.43-.37.14.05.14.23-.14.65-.22,1.6c-.06.57-.12,1.63-.12,1.83s0,.54-.2.54-.2-.12-.2-.26a3,3,0,0,0-.11-.94c-.12-.4-.37-.89-1.49-1-.77-.09-3.6-.15-4.09-.15a.19.19,0,0,0-.2.21v2.51c0,1,0,4.29,0,4.89.09,2,.52,2.35,3.32,2.35a8,8,0,0,0,2.74-.32c.75-.31,1.09-.89,1.29-2.06.06-.31.11-.43.26-.43s.17.23.17.43-.23,2.17-.37,2.77-.43.75-1.55.75c-2.14,0-3.71-.06-4.83-.09s-1.8-.08-2.23-.08c-.06,0-.54,0-1.14,0s-1.23.05-1.66.05-.46-.05-.46-.23.06-.17.23-.17A4.22,4.22,0,0,0,493,225c.57-.12.71-.75.83-1.57.14-1.21.14-3.46.14-6.21Z" />
            <path class="cls-1" d="M530,221.52h.12c.77-2.63,3.4-10.93,4.4-14.58a6.34,6.34,0,0,0,.32-1.83c0-.52-.32-.86-1.63-.86-.15,0-.26-.09-.26-.2s.14-.2.48-.2c1.46,0,2.66.08,2.92.08s1.43-.08,2.32-.08c.25,0,.4.05.4.17s-.09.23-.26.23a3.9,3.9,0,0,0-1,.14c-.86.31-1.32,1.52-1.86,3.09-1.26,3.63-4.15,12.72-5.26,15.92-.74,2.12-.89,2.46-1.2,2.46s-.46-.37-.86-1.48L523,208.45H523c-1,2.75-5,13.24-6,15.9-.45,1.2-.6,1.51-.94,1.51s-.43-.31-1-2.26L510,206.65c-.46-1.52-.83-2-1.57-2.23a4.81,4.81,0,0,0-1.29-.17c-.14,0-.26,0-.26-.2s.17-.2.46-.2c1.49,0,3.06.08,3.37.08s1.52-.08,2.95-.08c.4,0,.51.08.51.2s-.14.2-.28.2a2.85,2.85,0,0,0-1.12.17.74.74,0,0,0-.37.69,23.55,23.55,0,0,0,.54,2.63c.43,1.83,3.41,12.32,3.84,13.83h0l6.06-16.32c.43-1.17.55-1.32.72-1.32s.37.38.71,1.35Z" />
            <path class="cls-1" d="M570.72,221.52h.11c.77-2.63,3.4-10.93,4.4-14.58a6.34,6.34,0,0,0,.32-1.83c0-.52-.32-.86-1.63-.86-.15,0-.26-.09-.26-.2s.14-.2.49-.2c1.45,0,2.66.08,2.91.08s1.43-.08,2.32-.08c.26,0,.4.05.4.17s-.09.23-.26.23a3.9,3.9,0,0,0-1,.14c-.86.31-1.32,1.52-1.86,3.09-1.26,3.63-4.14,12.72-5.26,15.92-.74,2.12-.89,2.46-1.2,2.46s-.46-.37-.86-1.48l-5.6-15.93h-.06c-1,2.75-5,13.24-6,15.9-.45,1.2-.6,1.51-.94,1.51s-.43-.31-1-2.26l-5.09-16.95c-.46-1.52-.83-2-1.57-2.23a4.81,4.81,0,0,0-1.29-.17c-.14,0-.26,0-.26-.2s.18-.2.46-.2c1.49,0,3.06.08,3.38.08s1.51-.08,2.94-.08c.4,0,.52.08.52.2s-.15.2-.29.2a2.85,2.85,0,0,0-1.12.17.74.74,0,0,0-.37.69,22.09,22.09,0,0,0,.55,2.63c.42,1.83,3.4,12.32,3.83,13.83h0l6.07-16.32c.42-1.17.54-1.32.71-1.32s.37.38.72,1.35Z" />
            <path class="cls-1" d="M592.44,203.45c6.44,0,11.24,4.06,11.24,10.72,0,6.4-4.52,11.69-11.41,11.69-7.83,0-11.09-6.09-11.09-11.23C581.18,210,584.55,203.45,592.44,203.45Zm.6,21.33c2.58,0,7.9-1.49,7.9-9.81,0-6.86-4.18-10.55-8.7-10.55-4.77,0-8.35,3.14-8.35,9.41C583.89,220.49,587.9,224.78,593,224.78Z" />
            <path class="cls-1" d="M609.74,212.14c0-4.46,0-5.26-.06-6.18s-.28-1.43-1.23-1.63a6,6,0,0,0-1-.08c-.11,0-.23-.06-.23-.17s.14-.23.46-.23c1.29,0,3.09.08,3.23.08.31,0,2.49-.08,3.37-.08a8.8,8.8,0,0,1,5.24,1.26,5,5,0,0,1,2,3.88c0,2.12-.89,4.23-3.46,6.61,2.34,3,4.29,5.54,6,7.32a5.94,5.94,0,0,0,3.48,2.06,7.46,7.46,0,0,0,1.12.08c.14,0,.26.09.26.17s-.17.23-.69.23h-2a5.85,5.85,0,0,1-3.06-.54c-1.23-.66-2.23-2.06-3.86-4.2-1.2-1.58-2.54-3.49-3.11-4.24a.49.49,0,0,0-.38-.17l-3.51-.05c-.15,0-.2.08-.2.22v.69c0,2.75,0,5,.14,6.21.09.82.26,1.45,1.12,1.57a11.44,11.44,0,0,0,1.28.11c.17,0,.23.09.23.17s-.14.23-.46.23c-1.57,0-3.37-.08-3.51-.08s-1.83.08-2.69.08c-.32,0-.46-.05-.46-.23s.06-.17.23-.17a4.22,4.22,0,0,0,.86-.11c.57-.12.71-.75.83-1.57.14-1.21.14-3.46.14-6.21Zm2.29,2.49a.36.36,0,0,0,.2.34,7.8,7.8,0,0,0,3,.46,3.66,3.66,0,0,0,2.14-.55c1-.68,1.75-2.23,1.75-4.4,0-3.57-1.89-5.72-5-5.72a8.91,8.91,0,0,0-1.91.17.35.35,0,0,0-.23.35Z" />
            <path class="cls-1" d="M633.7,217.23c0,4.2.11,6,.63,6.55s1.2.65,3.43.65c1.51,0,2.77,0,3.46-.85a4,4,0,0,0,.74-1.72c0-.23.09-.37.26-.37s.17.11.17.43a18.23,18.23,0,0,1-.43,2.86c-.2.65-.28.77-1.8.77-2.06,0-3.55-.06-4.75-.09s-2.08-.08-2.91-.08c-.12,0-.6,0-1.17,0s-1.21.05-1.63.05-.46-.05-.46-.23.06-.17.23-.17a4.13,4.13,0,0,0,.85-.11c.58-.12.72-.75.83-1.57.15-1.21.15-3.46.15-6.21v-5c0-4.46,0-5.26-.06-6.18s-.29-1.43-1.23-1.63a6,6,0,0,0-1-.08c-.12,0-.23-.06-.23-.17s.14-.23.46-.23c1.28,0,3.08.08,3.23.08s2.23-.08,3.09-.08c.31,0,.45.05.45.23s-.11.17-.23.17a7.72,7.72,0,0,0-.94.08c-.83.15-1.06.63-1.11,1.63s-.06,1.72-.06,6.18Z" />
            <path class="cls-1" d="M648.22,212.14c0-4.46,0-5.26-.05-6.18s-.29-1.43-1.23-1.63a6.17,6.17,0,0,0-1-.08c-.11,0-.23-.06-.23-.17s.15-.23.46-.23c1.29,0,3.09.08,3.23.08.35,0,2.15-.08,3.86-.08,2.83,0,8.07-.26,11.47,3.26a10.2,10.2,0,0,1,2.77,7.26,11.7,11.7,0,0,1-3.09,8.06c-1.23,1.29-3.8,3.29-8.66,3.29-1.23,0-2.74-.09-4-.17s-2.23-.17-2.38-.17-.54,0-1.14,0-1.23.05-1.66.05-.46-.05-.46-.23.06-.17.23-.17a4.22,4.22,0,0,0,.86-.11c.57-.12.72-.75.83-1.57.14-1.21.14-3.46.14-6.21Zm2.4,3.09c0,3,0,5.66.06,6.2a6.17,6.17,0,0,0,.32,2.17c.37.55,1.48,1.15,4.86,1.15a9.58,9.58,0,0,0,6.8-2.66c1.49-1.46,2.29-4.2,2.29-6.83a10.19,10.19,0,0,0-2.75-7.21c-2.68-2.83-5.94-3.23-9.35-3.23a7.74,7.74,0,0,0-1.85.2.52.52,0,0,0-.35.57c0,1,0,3.89,0,6.09Z" />
            <rect class="cls-1" x="9.59" y="36.47" width="163.82" height="5.45" />
            <rect class="cls-1" x="223.98" y="36.47" width="92.9" height="5.45" />
            <rect class="cls-1" x="569.88" y="16.43" width="67.61" height="5.45" />
            <rect class="cls-1" x="747.53" y="18.51" width="58.66" height="5.45" />
            <rect class="cls-1" x="814.85" y="28.23" width="79.02" height="5.45" />
            <rect class="cls-1" x="814.85" y="54.08" width="79.02" height="5.45" />
            <rect class="cls-1" x="814.85" y="82.7" width="79.02" height="5.45" />
            <rect class="cls-1" x="747.53" y="66.81" width="58.66" height="5.45" />
            <rect class="cls-1" x="571.03" y="65.22" width="65.21" height="5.45" />
            <rect class="cls-1" x="650.92" y="44.14" width="85.76" height="5.45" />
            <rect class="cls-1" x="220.77" y="115.06" width="129.68" height="5.45" />
            <rect class="cls-1" x="220.47" y="60.02" width="131.74" height="5.45" />
            <rect class="cls-1" x="61.99" y="83.44" width="100.07" height="5.45" />
            <rect class="cls-1" x="30.82" y="152.92" width="143.19" height="5.45" />
            <rect class="cls-1" x="90.87" y="88.15" width="20.43" height="67.79" />
            <rect class="cls-1" x="205.89" y="8.52" width="20.43" height="42.02" />
            <rect class="cls-1" x="314.67" y="8.52" width="20.43" height="42.02" />
            <rect class="cls-1" x="590.18" y="20.1" width="20.43" height="98.5" />
            <rect class="cls-1" x="765.92" y="20.1" width="20.43" height="98.5" />
            <rect class="cls-1" x="807.04" y="28.21" width="20.43" height="59.94" />
            <rect class="cls-1" x="881.12" y="28.21" width="20.43" height="59.94" />
            <rect class="cls-1" x="844.08" y="28.21" width="20.43" height="59.94" />
            <rect class="cls-1" x="260.28" y="3.32" width="20.43" height="37.47" />
            <rect class="cls-2" x="268.35" y="87.21" width="20.43" height="76.02" />
            <path class="cls-1" d="M222.66,60H201.6s5.22,71-15.6,104.59C186,164.61,222.66,152.89,222.66,60Z" />
            <path class="cls-2" d="M569.22,121.71l8.08,21s40.27-17.67,57.15-38.11C634.45,104.56,598.88,118.59,569.22,121.71Z" />
            <path class="cls-1" d="M745.66,121.2l8.08,21S794,124.48,810.89,104C810.89,104,775.31,118.07,745.66,121.2Z" />
            <path class="cls-1" d="M653.4,3.36h24.74s-15.27,69.49-53.69,93.51C624.45,96.87,650.26,57.19,653.4,3.36Z" />
            <path class="cls-1" d="M653,65.06s11.54,58.57,79.49,75.4l-12.83,22s-55-19.3-69-93.65l.55-2.78Z" />
            <path class="cls-1" d="M694,53.19h20.47S711.84,143.48,605.86,166C605.86,166,684.9,134,694,53.19Z" />
            <path class="cls-2" d="M841.41,3.22h24.18s-1.93,13.61-22,25h-7.69S840.64,15.54,841.41,3.22Z" />
            <path class="cls-1" d="M821.92,91.53h21s1,62.23-70.09,74.51C772.78,166,818.33,151,821.92,91.53Z" />
            <path class="cls-1" d="M878.94,95.53l14.41,4.3s-6,17.55-16.38,25.36h-4.83S878.94,107,878.94,95.53Z" />
            <path class="cls-1" d="M868,125l3.64,12.36s14.5-5.84,21.43-10.79l-.53-3.39S876.7,125.75,868,125Z" />
            <path class="cls-1" d="M888,111.89a47.34,47.34,0,0,1,5.54,21.8l8.82-4.68S900.87,122.35,888,111.89Z" />
            <path class="cls-1" d="M891.53,147.09s-13.53,1.18-20.17.26c0,0-4.81.65-4.81-6.24v-53H849.63v57.15s-2.74,15.8,16.66,18c1.17.13,21,.13,26.8-.26,0,0,14.21,1.11,16.41-20.52l1.07-29.13s-7.11,23.63-10.28,29A10.32,10.32,0,0,1,891.53,147.09Z" />
            <path class="cls-2" d="M60.7,3.36H84.24S77.43,113.22,7.62,159.78C7.62,159.78,57.63,98.94,60.7,3.36Z" />
            <rect class="cls-1" x="228.01" y="82.53" width="113.29" height="5.45" />
            <path class="cls-2" d="M519.31,34.77A60.17,60.17,0,0,0,411.8,31.61a60.18,60.18,0,0,0,0,110.33,60.18,60.18,0,0,0,107.51-3.15,60.16,60.16,0,0,0,0-104ZM464.76,16.42A43.75,43.75,0,0,1,507.2,70.76a60.25,60.25,0,0,0-18.41-12.52,60.09,60.09,0,0,0-52.11-31.61A43.56,43.56,0,0,1,464.76,16.42Zm-.85,103.88c-.48.41-1,.8-1.46,1.18-.54-.42-1.08-.85-1.6-1.29a43.82,43.82,0,0,1-13.37-19.85,43.93,43.93,0,0,1-17.42-13.56,44,44,0,0,1,17.42-13.57,43.79,43.79,0,0,1,13.37-19.84c.52-.45,1.06-.88,1.6-1.29.5.38,1,.77,1.46,1.17a43.87,43.87,0,0,1,12.85,18.08,43.8,43.8,0,0,1,22.7,15.45,43.26,43.26,0,0,1-3.62,4.14,43.74,43.74,0,0,1-19.08,11.31A43.91,43.91,0,0,1,463.91,120.3ZM392.09,86.78a43.76,43.76,0,0,1,54.35-42.44,60.25,60.25,0,0,0-11.92,17,60.07,60.07,0,0,0-29.91,52c0,1.39.05,2.78.14,4.15A43.64,43.64,0,0,1,392.09,86.78Zm72.67,70.35a43.55,43.55,0,0,1-28.08-10.2,43.85,43.85,0,0,1-14.36-44.14,60.58,60.58,0,0,0,12.2,9.38,60.1,60.1,0,0,0,54.55,34.76c1.32,0,2.62,0,3.92-.12A43.58,43.58,0,0,1,464.76,157.13Zm60.14-45.27a43.84,43.84,0,0,1-46.44,17.36,60.25,60.25,0,0,0,10.33-13.9A60.13,60.13,0,0,0,524.9,61.69a43.73,43.73,0,0,1,0,50.17Z" />
            <path class="cls-3" d="M35.75,338.24H61.51v-10l-6.36,7c-.66-.89-1.67-2.12-3-3.68q-4-4.68-6-7.36a72.36,72.36,0,0,1-9.7,13c-4-4.45-6.8-7.47-8.36-9a46,46,0,0,0,13.71-28.77l12.72.67a109.84,109.84,0,0,1-3,12.71q2.33,2.69,6.69,7.36a41.18,41.18,0,0,1,3.34,3.68V293.08H75.56v32.45A61.56,61.56,0,0,0,87.94,299.1l12.38,1.34A76.2,76.2,0,0,1,97,312.81q3.34,3.69,8.7,10a47.94,47.94,0,0,0,3.68,4.35l-9,8.36a27.81,27.81,0,0,1-2.68-3.34c-2.46-3.35-4.46-6.13-6-8.37q-3.34,5-8.7,12a56.87,56.87,0,0,0-7.36-7.36v9.7h25.76v12.38H75.56v7.69h32.78V370H28.73V358.31H61.51v-7.69H35.75Z" />
            <path class="cls-3" d="M136.78,318.5l2,1a45.08,45.08,0,0,0,4.34-7l1-2h-7.36v-11h22.41v-6.69h12.38v6.69h22.75v11H174.91a29.18,29.18,0,0,1-3.34,7h5c.44-2,.78-3.79,1-5.36l11,.67a1,1,0,0,1-.34.67,12.77,12.77,0,0,1-1,4H194v10h-8.36v4.69h7v9.7h-7v4.35h7V356h-7v4.68h9V370H169.56v3.34H159.19V351q-4,15.06-21.74,22.75-1-1.68-4-6l-2-3q-.35,7.35-7.7,8h-3.34c-2.24.22-4,.34-5.36.34a98.39,98.39,0,0,0-2-11.38h5c1.34,0,2-.88,2-2.67V345.6l-6,1.33-.67-13.71,6.69-1V318.84H114.7v-12h5.35v-13h11.38v13h4.34v12h-4.34v7Zm-3,34.46-1.68-9.37,6-.67c2-2.89,3.79-5.69,5.35-8.36-.67-.45-1.56-1-2.68-1.68a103.3,103.3,0,0,0-9.36-6v3.35a5.73,5.73,0,0,1,2.34-.34,9.93,9.93,0,0,1,2-.33,115.16,115.16,0,0,0,0,12.38l-4.34,1v20.74q12.36-4.34,17.06-12.71c-1.79.22-4.24.56-7.36,1C138.67,352.18,136.22,352.51,133.77,353Zm23.41-31.11a40,40,0,0,0,6.69-11.38H151.5l3.34,1.34c-.89,1.56-1.23,2-1,1.34q-5,8-7.36,11.37l2.34,1.34,3.68-6.69Zm-4.68,16h6.69v-4.35l-3.34,2.68v-5.69q-3.36,6-7,11.38a15.75,15.75,0,0,1,3-.34Zm17.06-10.37v4.69h5.35v-4.69Zm0,14.39v4.35h5.35v-4.35Zm0,14.05v4.68h5.35V356Z" />
            <path class="cls-3" d="M219.07,312.48l13.38.67q-.67,24.09-5,34.12-4,11-18.06,25.42a137.55,137.55,0,0,0-10.71-11.37Q219.41,345.6,219.07,312.48Zm6.69-16.73h31.45q.33,51.86,23.41,64.57-2.69,3-7.36,8.36a37.22,37.22,0,0,0-3.34,3.68q-24.09-17.73-25.76-64.9h-18.4Z" />
            <path class="cls-3" d="M290.66,339.91V296.09h69.58v43.82h-28.1v4.68h29.1V355.3h-29.1V360h32.78v10.71H285.64V360h33.12V355.3H289.65V344.59h29.11v-4.68Zm13.71-32.78v5.68h14.39v-5.68Zm0,21.74h14.39v-5.69H304.37Zm42.15-21.74H332.14v5.68h14.38Zm-14.38,21.74h14.38v-5.69H332.14Z" />
            <path class="cls-3" d="M408.08,339.91h41.48v11.71H434.5v8h17.06v11H405.4v-7H394.69v10h-12v-10H370.94v-10h11.71V350H373V313.82h9.7v-3.68H372V299.43h10.7v-6h12v6H405.4v10.71H394.69v3.68h9.71V350h-9.71v3.68H406.4v6h15.73v-8H408.08ZM380,323.52v4.35h5v-4.35Zm0,12.38v4.34h5V335.9Zm17.39-12.38h-5v4.35h5Zm0,16.72V335.9h-5v4.34Zm18.06-16.39q4,6.36,7.36,12l-9.36,3.68a124.82,124.82,0,0,0-8-14.39q4.35,7-.67-1.34a128,128,0,0,0,9.37-14.72l9,3.68q-1,1.35-2.68,4Zm-8.36-26.76h43.49v12H407.07Zm22.75,27.1,4,6a38.64,38.64,0,0,0,3.35,5.35l-9.37,4q-4-7.69-9-15.73a92,92,0,0,0,9.37-14.72l9,3.68q-4.35,7-1.67,2.68Q432.83,319.84,429.82,324.19Zm14.05,0c.44.67,1.34,1.9,2.68,3.68a60.68,60.68,0,0,0,4.68,7l-8.7,4.69q-1.33-2-4-7-3.69-5.69-5.36-8.7,4.69-6.69,9.37-14.72l8.7,4q-2,3-5.35,8C445,322.51,444.31,323.52,443.87,324.19Z" />
            <path class="cls-3" d="M471.3,373.36v-10H456.25v-10H471.3v-3.68H458.25V313.15H471.3v-3.68H456.92v-10H471.3v-6h11.37v6h13.39v10H482.67v3.68h12.72v36.46H482.67v3.68h13.05V357q5-9,5-37.13h-3.35v-12h3.68V293.41h12.05c0,2-.12,4.8-.34,8.36v6h15.05V357c0,1.12.34,1.67,1,1.67.89,0,1.45-.55,1.67-1.67q.66-5,1-13.38c.67.22,1.79.56,3.35,1a25.28,25.28,0,0,0,3,1.34q-1,10.38-2,16.39-1.68,8.7-8.36,8.7h-2.34q-9.37.33-9-11V319.84h-3.68q-.68,24.09-3,33.79a56.16,56.16,0,0,1-8.7,20.07,82.79,82.79,0,0,0-8-10.37h-10v10Zm-4-51.18v5.35h4v-5.35Zm0,13.05v5.35h4v-5.35Zm19.4-13.05h-4v5.35h4Zm0,18.4v-5.35h-4v5.35Z" />
            <path class="cls-3" d="M546.23,298.43q39.15-1.33,68.58-3l3.68,13q-14.06,1-28.43,1.34v17.06h31.78v13.72H590.06V373H574.67V340.58H542.55V326.86h32.12V310.81q-13,.33-26.09,1c-.23-1.78-.67-4.46-1.34-8Q546.56,300.11,546.23,298.43Z" />
            <path class="cls-3" d="M695.76,312.48l11,4.68a158,158,0,0,1-8.36,16.39l-10.37-5v7.69h20.41v12.38H688.07v24.75H674.69V348.61H656c0,1.34.11,3.12.33,5.35a29.4,29.4,0,0,0,.34,5.35q-5,1.35-13.39,3.68-9,2-13.71,3.35l-2.68-12.71q4.68-1,9-2V324.86h-7.7V312.14h7.7V293.41H648.6v18.73h6.69v12.72H648.6v23.41a7.62,7.62,0,0,0,2.67-.67c1.34-.22,2.45-.44,3.35-.67v-10.7h20.07v-8l-10.37,5.35q-4.7-9.69-8.36-15.72l10.7-5.35q2,3.69,5.35,9.37c1.12,2,2,3.57,2.68,4.68V309.47H657v-12H705.8v12H688.07v18.4A160.55,160.55,0,0,0,695.76,312.48Z" />
            <path class="cls-3" d="M737.91,373.36H724.53V348.61L718.18,355c-.23-.66-.79-2-1.67-4q-2.68-6.69-4-10.37,10-8,19.74-23.08l13,4.35c-1.33,2.23-3.46,5.35-6.35,9.36a7.37,7.37,0,0,1-1,1.67ZM763,293.08h13.38v7.36h17.07v12H776.38v5H763v-5H744.27v4.35H730.89v-4.35H713.83v-12h17.06v-7.36h13.38v7.36H763Zm18.74,50.17q4,1,13,4a121.58,121.58,0,0,1-3,16.39q-2,8-14.38,7.7H761.67Q748.28,372,749,358.64V319.5h13.38v13.05a185.76,185.76,0,0,0,21.41-12.71l9,9.7a225.55,225.55,0,0,1-30.45,16.73V356q-.33,4.68,4,4.35h9q4.35,0,4.68-4a5.29,5.29,0,0,1,.34-1.34Q781.41,347.61,781.74,343.25Z" />
            <path class="cls-3" d="M801.14,373.36V295.42h76.27v77.94h-13V370H814.19v3.34Zm61.22-49.17H816.19v-6.36h17.4v-2.34H818.2V308.8h15.39v-3.68h-19.4V347.6q10.37-3,15.38-6h-10.7V326.86h40.81v13.72l4.35,3.68a30.89,30.89,0,0,1-5.35,4.35l5.68,2.67V305.12H845v3.68H860v6.69H845v2.34h17.4ZM818.2,356a15.43,15.43,0,0,0-1-1.67,48.58,48.58,0,0,0-3-5v12.37h50.17V352l-4.68,6.69q-3-1.67-11-5.68a20.4,20.4,0,0,1-.33,3,10.21,10.21,0,0,1-.34,3q-10.37.67-24.75,2.34l-1.34-6,8.7-1v-4A72.82,72.82,0,0,1,818.2,356Zm12-23.09v2.68h18.07v-2.68Zm16.4,10.37,4.68,2a34.12,34.12,0,0,0,4.35-3.68h-13l-1.34,1.34v10.71l7.36-.67-7-3.68Z" />
            <path class="cls-1" d="M457.9,456.1a22.92,22.92,0,0,1,10.7,19.58,23.35,23.35,0,0,1-3.25,12.05,21.1,21.1,0,0,1-8.73,8.32q-5.49,2.81-14,2.8-9.12,0-14.5-2.69a20.61,20.61,0,0,1-8.6-8.15,23.79,23.79,0,0,1-3.23-12.33,22.75,22.75,0,0,1,2.59-10.84,26.09,26.09,0,0,1,7.76-8.74,24.93,24.93,0,0,1-5.1-7.3,19.9,19.9,0,0,1-1.71-8.16,20.71,20.71,0,0,1,2.84-10.72,19.53,19.53,0,0,1,8-7.53,25.15,25.15,0,0,1,11.84-2.68,24.1,24.1,0,0,1,11.63,2.71,20.05,20.05,0,0,1,7.91,7.7,21,21,0,0,1,2.89,10.72,18.44,18.44,0,0,1-1.51,7.5A29.1,29.1,0,0,1,457.9,456.1Zm-15.32,7.66a12.62,12.62,0,0,0-8.71,3.15,9.74,9.74,0,0,0-3.53,7.5,10.2,10.2,0,0,0,3.33,7.71,12.22,12.22,0,0,0,8.76,3.15,12.53,12.53,0,0,0,8.73-2.94,9.78,9.78,0,0,0,3.25-7.61,10.22,10.22,0,0,0-3.38-7.81A11.91,11.91,0,0,0,442.58,463.76ZM442,433.54a7.8,7.8,0,0,0-5.82,2.32,8.14,8.14,0,0,0-2.28,5.95,8.36,8.36,0,0,0,2.41,6.08,8.52,8.52,0,0,0,11.91.05,8.59,8.59,0,0,0,2.33-6.23,7.82,7.82,0,0,0-2.35-5.9A8.55,8.55,0,0,0,442,433.54Z" />
            <path class="cls-1" d="M499.64,419.71l12.21,6.1-13.19,25.6A21.91,21.91,0,0,1,515,458.28a23.4,23.4,0,0,1,6.71,17.06,23.61,23.61,0,0,1-40.49,16.41,22.84,22.84,0,0,1-6.79-16.26,24.59,24.59,0,0,1,1.05-7,71.28,71.28,0,0,1,4.84-10.86ZM497.84,465a8.83,8.83,0,0,0-6.71,2.89,11,11,0,0,0,.13,14.58,8.92,8.92,0,0,0,6.63,3,9.32,9.32,0,0,0,6.87-2.92,10.12,10.12,0,0,0,2.87-7.38,10,10,0,0,0-2.8-7.25A9.3,9.3,0,0,0,497.84,465Z" />
            <path class="cls-1" d="M545.27,440.2H531.49q1.07-8.35,6-13.43a23.06,23.06,0,0,1,17.26-7.06,21.84,21.84,0,0,1,15.55,5.89,18.54,18.54,0,0,1,6.27,14,16.27,16.27,0,0,1-2.74,9.17,19.53,19.53,0,0,1-8,6.71,20.35,20.35,0,0,1,10.73,7.15,19.28,19.28,0,0,1,3.87,12A22.71,22.71,0,0,1,573,491.8q-7.49,7.05-19.06,7.05-10.95,0-17.87-6.64t-7.59-18h14.19q.93,5.83,4.08,8.68a12.48,12.48,0,0,0,16.29-.38,10.84,10.84,0,0,0-1.16-16.55q-4.46-3.54-12.85-3.64V450a23.27,23.27,0,0,0,7.71-1.61,9.62,9.62,0,0,0,3.94-3.31,7.84,7.84,0,0,0,1.41-4.45,6.72,6.72,0,0,0-2.15-5.1,7.91,7.91,0,0,0-5.64-2,9.14,9.14,0,0,0-5.58,1.86A9.29,9.29,0,0,0,545.27,440.2Z" />
            <path class="cls-1" d="M610.62,419.71a24.66,24.66,0,0,1,14.21,4q6,4,9.23,12.37t3.25,23.18q0,15-3.31,23.36t-8.88,12.27q-5.6,3.92-14.09,3.92t-14.29-4q-5.79-3.94-9.07-12T584.39,460q0-20.75,6.41-30.07Q597.91,419.71,610.62,419.71Zm.33,13.62a9.83,9.83,0,0,0-6.44,2.33q-2.81,2.34-4.29,8t-1.48,16.65q0,14.28,3.39,19.74t8.82,5.46q5.22,0,8.28-5.17,3.74-6.25,3.74-20.9,0-14.86-3.35-20.49C617.38,435.21,614.5,433.33,611,433.33Z" />
            <path class="cls-1" d="M646.62,462.68h26v13h-26Z" />
            <path class="cls-1" d="M696.56,421.6h35.29v13.27H707.26l-3.07,13.68a19.81,19.81,0,0,1,2.51-.54,16.21,16.21,0,0,1,2.35-.18,22.78,22.78,0,0,1,17,7q6.78,7,6.78,17.9a25.32,25.32,0,0,1-7.5,18.57,24.76,24.76,0,0,1-18.26,7.56,25.22,25.22,0,0,1-25.61-20.49h15.06a12.91,12.91,0,0,0,4.58,5.35,11.44,11.44,0,0,0,6.27,1.82,10.85,10.85,0,0,0,8.1-3.43,13.37,13.37,0,0,0,.23-17.39,9.93,9.93,0,0,0-12.14-2.18,16.76,16.76,0,0,0-4.48,3.53l-11.68-2.61Z" />
            <path class="cls-1" d="M763.5,498.85l-12.19-6.05,13.2-25.66a22,22,0,0,1-16.32-6.86,23.38,23.38,0,0,1-6.72-17A23.63,23.63,0,0,1,782,426.79a22.88,22.88,0,0,1,6.79,16.3,24.22,24.22,0,0,1-1,7,71.29,71.29,0,0,1-4.84,10.82Zm1.79-45.28a8.82,8.82,0,0,0,6.71-2.92,10.94,10.94,0,0,0-.13-14.56,8.91,8.91,0,0,0-6.63-3,9.3,9.3,0,0,0-6.87,2.93,10.83,10.83,0,0,0-.07,14.59A9.29,9.29,0,0,0,765.29,453.57Z" />
            <path class="cls-1" d="M811.23,440.2H797.45q1.08-8.35,6-13.43a23.06,23.06,0,0,1,17.26-7.06,21.82,21.82,0,0,1,15.55,5.89,18.55,18.55,0,0,1,6.28,14,16.34,16.34,0,0,1-2.74,9.17,19.63,19.63,0,0,1-8,6.71,20.31,20.31,0,0,1,10.73,7.15,19.28,19.28,0,0,1,3.87,12,22.68,22.68,0,0,1-7.48,17.18q-7.49,7.05-19.05,7.05-11,0-17.88-6.64t-7.58-18h14.19q.92,5.83,4.07,8.68a12.48,12.48,0,0,0,16.29-.38,10.84,10.84,0,0,0-1.16-16.55q-4.46-3.54-12.85-3.64V450a23.15,23.15,0,0,0,7.71-1.61,9.62,9.62,0,0,0,3.94-3.31,7.84,7.84,0,0,0,1.41-4.45,6.72,6.72,0,0,0-2.15-5.1,7.89,7.89,0,0,0-5.64-2,9.14,9.14,0,0,0-5.58,1.86A9.34,9.34,0,0,0,811.23,440.2Z" />
            <path class="cls-1" d="M892.26,456.1A22.92,22.92,0,0,1,903,475.68a23.35,23.35,0,0,1-3.25,12.05,21.1,21.1,0,0,1-8.73,8.32q-5.49,2.81-14,2.8-9.12,0-14.5-2.69a20.61,20.61,0,0,1-8.6-8.15,23.79,23.79,0,0,1-3.23-12.33,22.75,22.75,0,0,1,2.59-10.84A26.09,26.09,0,0,1,861,456.1a24.74,24.74,0,0,1-5.1-7.3,19.9,19.9,0,0,1-1.71-8.16A20.71,20.71,0,0,1,857,429.92a19.53,19.53,0,0,1,8-7.53,25.15,25.15,0,0,1,11.84-2.68,24.1,24.1,0,0,1,11.63,2.71,20.05,20.05,0,0,1,7.91,7.7,21,21,0,0,1,2.89,10.72,18.44,18.44,0,0,1-1.51,7.5A29.1,29.1,0,0,1,892.26,456.1Zm-15.32,7.66a12.62,12.62,0,0,0-8.71,3.15,9.74,9.74,0,0,0-3.53,7.5,10.2,10.2,0,0,0,3.33,7.71,12.22,12.22,0,0,0,8.76,3.15,12.53,12.53,0,0,0,8.73-2.94,9.78,9.78,0,0,0,3.25-7.61,10.22,10.22,0,0,0-3.38-7.81A11.91,11.91,0,0,0,876.94,463.76Zm-.56-30.22a7.8,7.8,0,0,0-5.82,2.32,8.14,8.14,0,0,0-2.28,5.95,8.32,8.32,0,0,0,2.41,6.08,8.52,8.52,0,0,0,11.91.05,8.59,8.59,0,0,0,2.33-6.23,7.79,7.79,0,0,0-2.36-5.9A8.51,8.51,0,0,0,876.38,433.54Z" />
            <path class="cls-1" d="M0,477.85l16.44-3.29q1.64,10.53,12.16,10.52,10.18-.33,10.52-10.19.33-10.18-15.78-10.19H16.77V452.53H23q14.46,0,14.47-9.53-.33-9.19-9.21-9.21-9.21.68-11.5,10.52L1.31,441.36Q5.91,422,29.59,421.63q22.68.66,24.33,19.07.31,13.49-15.46,17.09v.33q15.78,2.64,17.1,17.75-1.32,20.06-27.94,20.72Q5.25,497.9,0,477.85Z" />
            <path class="cls-1" d="M59.37,477.85l16.44-3.29Q77.45,485.09,88,485.08q10.19-.33,10.52-10.19.33-10.18-15.78-10.19H76.14V452.53h6.25q14.46,0,14.46-9.53-.33-9.19-9.21-9.21-9.19.68-11.5,10.52l-15.45-2.95Q65.28,422,89,421.63q22.68.66,24.33,19.07.31,13.49-15.45,17.09v.33q15.78,2.64,17.09,17.75Q113.61,495.93,87,496.59,64.63,497.9,59.37,477.85Z" />
            <path class="cls-1" d="M164.11,465h-46V453.85h46Z" />
            <path class="cls-1" d="M167.27,477.85l16.44-3.29q1.64,10.53,12.16,10.52,10.19-.33,10.52-10.19.33-10.18-15.78-10.19H184V452.53h6.24q14.46,0,14.47-9.53-.33-9.19-9.21-9.21-9.21.68-11.5,10.52l-15.46-2.95q4.6-19.39,28.28-19.73,22.68.66,24.32,19.07.33,13.49-15.45,17.09v.33q15.78,2.64,17.1,17.75-1.32,20.06-28,20.72Q172.53,497.9,167.27,477.85Z" />
            <path class="cls-1" d="M280.23,439.71l-15.12,2.63q-2-8.88-9.87-8.55-12.81-.32-13.15,23,5.59-7.56,16.44-7.89,21.71,1.32,23.34,23-1.32,23.67-26.63,24.66-29.58,1-28.93-37.15,1-36.82,28.6-37.81Q275.63,421.63,280.23,439.71Zm-13.81,33.21q-.33-11.51-11.83-11.84-11.19.33-11.84,11.18.33,11.83,12.49,12.49Q265.76,483.76,266.42,472.92Z" />
            <path class="cls-1" d="M351.76,438.4l10.85,4.6a156.6,156.6,0,0,1-8.22,16.11l-10.19-4.93v7.56h20.06V473.9H344.2v24.33H331.05V473.9H312.64c0,1.32.11,3.07.33,5.26a29.57,29.57,0,0,0,.33,5.26q-4.94,1.32-13.15,3.62-8.88,2-13.48,3.29L284,478.83c3.07-.66,6-1.31,8.88-2v-26.3h-7.56V438.07h7.56V419.66h12.49v18.41H312v12.49h-6.58v23a7.51,7.51,0,0,0,2.63-.65c1.32-.22,2.41-.44,3.29-.66V461.74h19.72v-7.89l-10.19,5.26q-4.61-9.54-8.22-15.45l10.52-5.26q2,3.62,5.26,9.2c1.1,2,2,3.51,2.63,4.6V435.44H313.63V423.6h48v11.84H344.2v18.08A159.85,159.85,0,0,0,351.76,438.4Z" />
            <rect class="cls-1" x="388.16" y="419.64" width="4.02" height="83.18" />
        </g>
    </g>
        </svg>
      </div>
      <img
        data-aos="fade-right"
        data-aos-duration="1500"
        data-aos-delay="500"
        class="draw1"
        src="~@/projects/rose/s1/draw1.png"
        alt=""
      />
      <img
        data-aos="fade-left"
        data-aos-duration="1500"
        data-aos-delay="1000"
        class="draw2"
        src="~@/projects/rose/s1/draw2.png"
        alt=""
      />
    </div>
    <div class="sub-section s2">
      <swiper :options="swiperOptions" :instanceName="1">
        <swiper-slide
          v-for="item of swiperList"
          :key="item.id"
          :style="{ backgroundImage: `url(${item.imgUrl})` }"
          data-aos="fade-up"
        >
          <p>{{ item.title }}</p>
          <p>{{ item.content }}</p>
          <div class="mask"></div>
        </swiper-slide>
        <div
          v-if="isMobile"
          class="swiper-button-prev"
          slot="button-prev"
        ></div>
        <div
          v-if="isMobile"
          class="swiper-button-next"
          slot="button-next"
        ></div>
      </swiper>
      <img
        data-aos="fade-right"
        data-aos-duration="1000"
        class="draw3"
        src="~@/projects/rose/s1/draw1.png"
        alt=""
      />
      <img
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-delay="500"
        v-if="!isMobile"
        class="draw4"
        src="~@/projects/rose/s1/draw4.png"
        alt=""
      />
    </div>
    <div class="sub-section">
      <div class="flex-ja" v-if="!isMobile">
        <div class="left">
          <div>
            <div class="item" data-aos="fade-down" data-aos-duration="1000">
              <p>64快速道路</p>
            </div>
          </div>
          <div>
            <div
              class="item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <p>淡江大橋施工圖</p>
            </div>
          </div>
          <div>
            <div
              class="item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <p>61快速道路</p>
            </div>
          </div>
        </div>
        <div class="right">
          <div
            class="txt"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="0"
          >
            <h2>64快速道路</h2>
            <p>
              7分鐘車程，接軌64快速道路，一線直達三重、板橋、中和、新店市心。
            </p>
          </div>
          <div
            class="txt"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <h2>61快速道路</h2>
            <p>
              車行11分快速串聯61快速道路(西濱)，西半部差旅即刻出發，沿途海線美景相隨。
            </p>
          </div>
          <div
            class="txt"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <h2>Ubike</h2>
            <p>
              散步3分鐘，秒抵Ubike站點，微風、陽光相伴，來場愜意單車輕旅行。
            </p>
          </div>
          <div
            class="txt"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <h2>淡江大橋</h2>
            <p>
              淡江大橋位於淡水、八里交界，已納入台61線範圍，估計完工後，兩地可省下約25分鐘車程。
            </p>
          </div>
        </div>
      </div>
      <div v-if="isMobile">
        <div class="top">
          <div
            class="txt"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="0"
          >
            <h2>64快速道路</h2>
            <p>
              7分鐘車程，接軌64快速道路，一線直達三重、板橋、中和、新店市心。
            </p>
          </div>
          <div
            class="txt"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <h2>61快速道路</h2>
            <p>
              車行11分快速串聯61快速道路(西濱)，西半部差旅即刻出發，沿途海線美景相隨。
            </p>
          </div>
          <div
            class="txt"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <h2>Ubike</h2>
            <p>
              散步3分鐘，秒抵Ubike站點，微風、陽光相伴，來場愜意單車輕旅行。
            </p>
          </div>
          <div
            class="txt"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <h2>淡江大橋</h2>
            <p>
              淡江大橋位於淡水、八里交界，已納入台61線範圍，估計完工後，兩地可省下約25分鐘車程。
            </p>
          </div>
        </div>
        <div class="bottom">
          <swiper :options="swiperOptions2" :instanceName="1">
            <swiper-slide
              v-for="item of swiperList2"
              :key="item.id"
              :style="{ backgroundImage: `url(${item.imgUrl})` }"
              data-aos="fade-up"
            >
              <p>{{ item.title }}</p>
            </swiper-slide>
            <div
              v-if="isMobile"
              class="swiper-button-prev"
              slot="button-prev"
            ></div>
            <div
              v-if="isMobile"
              class="swiper-button-next"
              slot="button-next"
            ></div>
          </swiper>
        </div>
      </div>
    </div>
    <div class="sub-section">
      <img
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-delay="0"
        class="zoom"
        v-if="isMobile"
        src="~@/projects/rose/s1/zoom.png"
        alt=""
      />
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        data-aos-delay="500"
        class="mapbox"
      >
        <img width="100%" src="~@/projects/rose/s1/map.jpg" alt="" />
      </div>

      <div v-if="isMobile" class="mapbox-zoom">
        <img src="~@/projects/rose/s1/map.jpg" alt="" />

        <div class="close">
          <font-awesome-icon icon="times" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "~@/assets/style/variableDefault.scss";
/* 螢幕尺寸標準 */
.section1 {
  width: 100%;
  height: auto;
  

  .topbg{
    background-image: url("~@/projects/rose/s1/bg.jpg");
  }

  .s2{
    padding-top: 80px;
  }

  .sub-section {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #1f7fb2;

    &:nth-child(3) {
      padding: 5vw 10vw;
      .flex-ja {
        height: 100%;

        .left {
          width: 60%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;

          > div {
            background: transparent;
            box-sizing: border-box;

            .item {
              background: #eee;
              width: 100%;
              height: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              position: relative;

              p {
                position: absolute;
                right: 10px;
                bottom: 10px;
                color: #fff;
                letter-spacing: 2px;
              }
            }

            &:nth-child(1) {
              height: 65%;
              width: 100%;
              padding-bottom: 16px;

              .item {
                background-image: url("~@/projects/rose/s1/a.jpg");
              }
            }

            &:nth-child(2) {
              height: 35%;
              width: 50%;
              padding-right: 8px;

              .item {
                background-image: url("~@/projects/rose/s1/b.jpg");
              }
            }

            &:nth-child(3) {
              height: 35%;
              width: 50%;
              padding-left: 8px;

              .item {
                background-image: url("~@/projects/rose/s1/c.jpg");
              }
            }
          }
        }
        .right {
          width: 40%;
          padding: 3vw;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .txt {
            text-align: left;
            margin-bottom: 40px;

            h2 {
              font-size: 32px;
              font-weight: bold;
              letter-spacing: 2px;
              color: #fff100;
              margin-bottom: 10px;
            }

            p {
              color: #fff;
              font-size: 24px;
              letter-spacing: 2px;
              line-height: 1.5;
            }
          }
        }
      }
    }

    &:nth-child(4) {
      height: auto;
      .mapbox {
        width: 100%;
        height: auto;
        background-image: url(~@/projects/rose/s1/map.jpg);
        background-size: cover;

        img {
          visibility: hidden;
        }
      }
    }
  }

  svg {
    width: 60vw;
    max-width: 850px;
    position: absolute;
    left: 10vw;
    top: 16vw;
    //top: 22vw;
    z-index: 1;
    

    .cls-1 {
      fill: #eadf37;
    }
    .cls-2 {
      fill: #fff;
    }
    .cls-3 {
      fill: #f5e929;
    }
    .cls-4 {
      fill: #178dc1;
    }
    .cls-5 {
      fill: none;
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 1.93px;
    }
  }

  .draw1 {
    position: absolute;
    left: 5vw;
    top: 2vw;
    width: 14vw;
    max-width: 195px;
    z-index: 1;
    pointer-events: none;

    animation: float 10s ease-in-out 0s infinite alternate-reverse;
  }

  .draw2 {
    position: absolute;
    right: 0;
    top: 5vw;
    width: 100vw;
    max-width: 800px;
    z-index: 1;
    pointer-events: none;
  }

  .draw3 {
    position: absolute;
    left: 2vw;
    bottom: 2vw;
    width: 25vw;
    max-width: 250px;
    z-index: 1;
    pointer-events: none;

    animation: float 10s ease-in-out 0s infinite alternate-reverse;
  }

  .draw4 {
    position: absolute;
    right: 2vw;
    bottom: -10vw;
    width: 25vw;
    max-width: 250px;
    z-index: 1;
    pointer-events: none;

    animation: float 10s ease-in-out 0s infinite alternate-reverse;
  }

  .swiper-container {
    padding: 0 5vw 0 5vw;

    .swiper-slide {
      height: 80vh;
      border-radius: 100px;

      &:hover {
        p {
          opacity: 1;
        }

        .mask {
          opacity: 1;
        }
      }

      .mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 100px;
        opacity: 0;
        transition: all 0.3s;
        z-index: 1;
      }

      p {
        color: #fff;
        letter-spacing: 3px;
        line-height: 1.5;
        position: absolute;
        text-align: justify;
        text-align-last: center;
        transition: all 0.3s;
        opacity: 0;
        z-index: 2;

        &:nth-child(1) {
          width: 100%;
          left: 0;
          top: 20vh;
          font-size: 36px;
          font-weight: bold;
        }

        &:nth-child(2) {
          width: 100%;
          left: 0;
          top: 30vh;
          font-size: 24px;
          margin: 0 auto;
          padding: 0 2vw 0 2vw;
        }
      }
    }
  }
}

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .section1 {
    width: 100vw;
    height: auto;


    .topbg{
    background-image: url("~@/projects/rose/s1/bg_m.jpg");
    background-size: cover;
  }

    .sub-section {
      margin-top: -1px;
      height: 100vh;
      width: 100%;
      background-color: #1f7fb2;

      &:nth-child(2) {
        height: auto !important;
        padding-bottom: 10vh;
        .swiper-container {
          padding: 0 50px 0 50px;

          .swiper-slide {
            height: 400px;
            border-radius: 50px;

            &:hover {
              p {
                opacity: 1;
              }

              .mask {
                opacity: 1;
              }
            }

            .mask {
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.6);
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 100px;
              opacity: 0;
              transition: all 0.3s;
              z-index: 1;
              border-radius: 50px;
            }

            p {
              color: #fff;
              letter-spacing: 3px;
              line-height: 1.5;
              position: absolute;
              text-align: justify;
              text-align-last: center;
              transition: all 0.3s;
              opacity: 0;
              z-index: 2;

              &:nth-child(1) {
                top: 18vh;
                font-size: 28px;
                text-align: center !important;
              }

              &:nth-child(2) {
                top: 25vh;
                font-size: 16px;
                padding: 0 15px 0 15px;
              }
            }
          }
        }
      }

      &:nth-child(3) {
        height: auto !important;
        padding: 0;

        .top {
          text-align: left;
          padding: 5vw 10vw;

          .txt {
            margin-bottom: 20px;
            h2 {
              font-size: 24px;
              font-weight: bold;
              color: #fff100;
              margin-bottom: 12px;
            }
            p {
              font-size: 16px;
              color: #fff;
              letter-spacing: 1px;
              line-height: 1.5;
            }
          }
        }
        .swiper-container {
          padding: 0;
          width: 100vw;

          .swiper-slide {
            height: 280px;
            border-radius: 0px;

            p {
              color: #fff;
              letter-spacing: 3px;
              position: absolute;
              top: unset;
              width: unset;
              opacity: 1;
              font-size: 16px;
              right: 15px;
              bottom: 10px;
              left: unset;
            }
          }
        }
      }

      &:nth-child(4) {
        padding-top: 35vw;

        .mapbox {
          z-index: 10;
          position: relative;
        }
        .zoom {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
        }
        .mapbox-zoom {
          position: fixed;
          width: 100%;
          height: 100%;
          z-index: 1000;
          top: 0;
          left: 0;
          background: #eee;
          display: none;
          overflow: auto;

          .close {
            width: 60px;
            height: 60px;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 100;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              font-size: 32px;
              color: #fff;
              cursor: pointer;
              position: relative;
              top: inherit;
            }
          }

          img {
            height: 100%;
          }
        }
      }
    }

    svg {
      width: 75vw;
      position: absolute;
      left: 50%;
      top: 30vw;
      //top: 50vw;
      transform: translate(-50%, 0);
      z-index: 1;
    }

    .draw1 {
      position: absolute;
      left: 5vw;
      top: 2vw;
      width: 40vw;
      z-index: 1;
      pointer-events: none;
    }

    .draw2 {
      position: absolute;
      right: -8vw;
      top: 65vw;
      width: 200vw;
      max-width: 400px;
      z-index: 1;
      pointer-events: none;
    }

    .draw3 {
      position: absolute;
      left: 50vw;
      bottom: 0;
      width: 28vh;
      z-index: 1;
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from "@/utils";

export default {
  name: "section1",

  data() {
    return {
      isMobile,
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: true,
        speed: 1000,
        slidesPerView: isMobile ? 1 : 3,
        spaceBetween: isMobile ? 50 : 50,
        resistanceRatio: isMobile ? 1 : 0,
        loop: isMobile ? false : false,
      },
      swiperOptions2: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: true,
        speed: 1000,
        slidesPerView: isMobile ? 1 : 3,
        spaceBetween: isMobile ? 0 : 0,
        resistanceRatio: isMobile ? 1 : 0,
        loop: isMobile ? false : false,
      },
      swiperList: [
        {
          id: "0001",
          imgUrl: require("./s1/1.jpg"),
          title: "大崁國小",
          content:
            "新北市大崁國小，為八里區明星小學之一，課後社團活躍，注重學童課內外領域知識。",
        },
        {
          id: "0002",
          imgUrl: require("./s1/2.jpg"),
          title: "中山路商圈",
          content: "中山路商圈生活機能豐富，採買環境便利，食衣住行一把罩。",
        },
        {
          id: "0003",
          imgUrl: require("./s1/3.jpg"),
          title: "一分鐘全聯",
          content: "下樓過馬路，就到全聯採購，即興烹煮健康料理，享受溫馨晚餐。",
        },
      ],
      swiperList2: [
        {
          id: "0001",
          imgUrl: require("./s1/m-a.jpg"),
          title: "64快速道路",
        },
        {
          id: "0002",
          imgUrl: require("./s1/m-b.jpg"),
          title: "61快速道路",
        },
        {
          id: "0003",
          imgUrl: require("./s1/m-c.jpg"),
          title: "淡江大橋施工圖",
        },
      ],
    };
  },

  methods: {
    prev() {
      this.$refs.swiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.swiper.$swiper.slideNext();
    },
  },

  mounted() {
    if (isMobile) {
      $(".mapbox").click(function () {
        $(".mapbox-zoom").fadeIn();
      });
      $(".mapbox-zoom .close").click(function () {
        $(".mapbox-zoom").fadeOut();
      });
    }
  },
};
</script>
